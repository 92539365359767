import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "scrollbar"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "container__card"
}
const _hoisted_5 = {
  key: 1,
  class: "container__card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_v_md_preview_html = _resolveComponent("v-md-preview-html")!
  const _component_van_skeleton = _resolveComponent("van-skeleton")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_search = _resolveComponent("van-search")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_nav_bar, {
      title: _ctx.navBarTitle,
      fixed: "",
      "left-arrow": _ctx.module !== -1 ? true : false,
      border: _ctx.isDetails,
      onClickLeft: _ctx.onClickBack
    }, null, 8, ["title", "left-arrow", "border", "onClickLeft"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_van_skeleton, {
              title: "",
              row: 8,
              loading: _ctx.webContent ? false : true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_md_preview_html, {
                  html: _ctx.webContent,
                  "preview-class": "github-markdown-body"
                }, null, 8, ["html"])
              ]),
              _: 1
            }, 8, ["loading"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.module === -1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_van_cell, {
                    title: "使用说明",
                    "is-link": "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRootClick(1)))
                  }),
                  _createVNode(_component_van_cell, {
                    title: "常见问题",
                    "is-link": "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleRootClick(2)))
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_ctx.idStepArr.length === 0)
                    ? (_openBlock(), _createBlock(_component_van_search, {
                        key: 0,
                        modelValue: _ctx.searchValue,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchValue) = $event)),
                        placeholder: "请输入搜索关键词"
                      }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.treeList, (item) => {
                    return (_openBlock(), _createBlock(_component_van_cell, {
                      key: item.id,
                      title: item.cateName,
                      "is-link": "",
                      onClick: ($event: any) => (_ctx.handleTreeClick(item))
                    }, null, 8, ["title", "onClick"]))
                  }), 128))
                ]))
          ]))
    ])
  ], 64))
}